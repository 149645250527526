import {
	Icon,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TablePagination,
	TableRow,
	Button,
	Popover,
} from '@material-ui/core';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MAX_ROW_IN_TABLE } from '../../../containers/app/view/tableConfig/const';
import Severity from '../../../containers/vcaManagement/dataTable/Severity/severityDisplay';
import MasterDetails from '../../../containers/vcaManagement/masterDetails/MasterDetails';
import { toLocaleDateString } from '../../../helpers/datesHelper';
import SkeletonTable from '../simpleTable/skeletonTable/skeletonTable';
import useStyles from './detectionsTable.css';
import {
	COLUMNS,
	detectionIconsMapping,
	detectionNameMapping,
	SEVERITIES_CONFIG,
	actionTypeToPayload,
	detectionActionTypes, directionTypes,
} from './detectionsTableConfig';
import PaginationActions from './pagination/paginationActions';
import TableHead from './tableHead/tableHead';
import TableRowActions from './tableRowActions/tableRowActions';
import { getDetectionDetails, updateDetection } from '../../../containers/vcaManagement/services/vcaRequests';
import { openSnackbar } from '../../../containers/snackbarAlert/controller/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import EndpointsGroup from '../endpointsGroup/endpointsGroup';
import TextWithTooltip from '../textWithTooltip/textWithTooltip';
import useTable from '../../customHooks/useTable';
import RemediationPopover from '../../../containers/vcaManagement/remediationPopover/remediationPopover';
import { remediationBuilder } from './helpers/parsers';
import { activeFeatures } from '../../../constants/activeFeatures';
import RemediationForm from '../../../containers/vcaManagement/remediationForm/remediationForm';
import EmptyTableFilters from './emptyTable/EmptyTableFilters';
import EmptyTableNoFilters from './emptyTable/EmptyTableNoFilters';
import CustomTooltip from '../Tooltip/CustomTooltip';
import { matchPath } from 'react-router';
import { closeFullScreen, openFullScreen } from '../../../containers/app/controller/redux/fullScreen/fullScreenActions';
import { VCA_RT_FEATURE_TOGGLE } from '../../../constants/featureToggles';

const NOT_FOUND_ROUTE = '/not-found';
const DEFAULT_SORT_KEY = VCA_RT_FEATURE_TOGGLE ? 'lastDetectionTime' : COLUMNS[1].sortParam;

const DetectionsTable = ({ fetchDetections, pageSize, pagesToPreload, filters, history }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const features = useSelector(state => state.account.features) || {};
	const [isMasterDetailsOpen, setIsMasterDetailsOpen] = useState(false);
	const [eventData, setEventData] = useState(null);
	const isFullScreen = useSelector(state => state.fullScreen.isOpen);
	const [isMasterDetailsLoading, setIsMasterDetailsLoading] = useState(false);
	const [canGoNext, setCanGoNext] = useState(true);
	const [canGoPrevious, setCanGoPrevious] = useState(true);

	const [remediationAnchorEl, setRemediationAnchorEl] = useState(null);
	const [remediationEvent, setRemediationEvent] = useState({});
	const [enableAutoRemediate, setEnableAutoRemediation] = useState(false);
	const [autoRemediationForm, setAutoRemediationForm] = useState(false);
	const [loadingAutoRemediationSubmit, setLoadingAutoRemediationSubmit] = useState(false);

	const { items, currentPage, totalItems, startRow, endRow, sortKey, sortDirection, isLoading, handlePageChange, handleSort, refetchPages, replaceItem } = useTable(fetchDetections, pageSize, pagesToPreload, DEFAULT_SORT_KEY, 'desc', filters);

	useEffect(() => {
		if (features.includes(activeFeatures.DEMO_MODE)) {
			setEnableAutoRemediation(true);
		} else {
			setEnableAutoRemediation(false);
		}
	}, []);

	useEffect(() => {
		const handleHashChange = () => {
			const eventId = getFullScreenEventId();
			if (eventId) {
				openMasterDetails(eventId, true);
				dispatch(openFullScreen());
			}
		};

		window.addEventListener('hashchange', handleHashChange);
		handleHashChange();
		return () => {
			window.removeEventListener('hashchange', handleHashChange);
			dispatch(closeFullScreen());
		};
	}, []);

	const getIconTooltipContent = detectionType => <p className={classes.iconTooltipText}>{detectionNameMapping[detectionType]}</p>;

	const getCurrentPage = () => items.slice(startRow, endRow + 1);

	const getFullScreenEventId = () => {
		const match = matchPath(window.location.hash, {
			path: '#/vca-management/event/:eventId',
			exact: false,
			strict: false,
		});
		return match ? match.params.eventId : null;
	};

	const openMasterDetails = async (eventId, fullScreen) => {
		const currentEvent = items.find(item => item.eventId === eventId);
		if (currentEvent?.isAssetsShown || fullScreen) {
			try {
				setIsMasterDetailsLoading(true);
				setIsMasterDetailsOpen(true);
				const data = await getDetectionDetails(eventId);
				setEventData(data);
				const currentEventDataIndex = items.map(detection => detection.eventId).indexOf(eventId);
				setCanGoPrevious(currentEventDataIndex > 0);
				setCanGoNext(currentEventDataIndex < totalItems - 1);
			} catch (error) {
				if (error.status === 404 && fullScreen) {
					history.push(NOT_FOUND_ROUTE);
					dispatch(closeFullScreen());
				} else {
					dispatch(openSnackbar('error', 'Failed to get detection details'));
					closeMasterDetails();
				}
			} finally {
				setIsMasterDetailsLoading(false);
			}
		} else {
			setIsMasterDetailsOpen(true);
			setEventData(currentEvent);
		}
	};

	const closeMasterDetails = () => {
		setIsMasterDetailsOpen(false);
		setEventData(null);
	};

	const closeFullScreenOnMD = async ({ eventId }) => {
		dispatch(closeFullScreen());
		await openMasterDetails(eventId);
		resetUrlToVcaManagement();
	};

	const resetUrlToVcaManagement = () => {
		const href = '/#/vca-management';
		window.location.href = href;
	};

	const handleDetectionUpdate = async (event, eventId, actionType) => {
		event && event.stopPropagation();
		try {
			const updatedDetection = await updateDetection(eventId, actionTypeToPayload[actionType]);
			if (detectionFuncByUpdateType[actionType]) {
				await detectionFuncByUpdateType[actionType](updatedDetection);
			}
		} catch {
			dispatch(openSnackbar('error', 'Unable to update the detection', 4000));
		}
	};

	const undoButtonAction = updatedDetection => <Button className={classes.undoDeleteButton} onClick={event => handleDetectionUpdate(event, updatedDetection.eventId, detectionActionTypes.UNDO_DELETE)}>Undo</Button>;

	const detectionFuncByUpdateType = {
		[detectionActionTypes.DELETE]: updatedDetection => onDelete(updatedDetection.eventId, undoButtonAction(updatedDetection)),
		[detectionActionTypes.UNDO_DELETE]: updatedDetection => handleUndoDelete(updatedDetection),
		[detectionActionTypes.HIGHLIGHT]: updatedDetection => insertUpdatedDetection(updatedDetection),
		[detectionActionTypes.REMOVE_HIGHLIGHT]: updatedDetection => insertUpdatedDetection(updatedDetection),
	};

	const onDelete = async (eventId, additionalAction) => {
		try {
			closeMasterDetails();
			await refetchPages();
			dispatch(openSnackbar('', 'Detection deleted', 10000, additionalAction, classes.deleteDetectionSnackbar));
		} catch {
			dispatch(openSnackbar('error', 'Unable to delete detection', 4000));
		}
	};

	const handleUndoDelete = async () => {
		try {
			await refetchPages();
			dispatch(openSnackbar('', 'Action undone', 4000, null, classes.deleteDetectionSnackbar));
		} catch {
			dispatch(openSnackbar('error', 'Unable to undo the action', 4000));
		}
	};

	const insertUpdatedDetection = updatedDetection => {
		const detectionIndex = items.map(detection => detection.eventId).indexOf(updatedDetection.eventId);
		replaceItem(updatedDetection, detectionIndex);
	};

	const onRemediation = (event, eventId) => {
		event.stopPropagation();
		setRemediationAnchorEl(event.currentTarget);
		const newRemediationData = items.find(item => item.eventId === eventId);
		setRemediationEvent(newRemediationData);
	};

	const onRemediationClose = () => {
		setRemediationAnchorEl(null);
		setRemediationEvent({});
	};

	const showAutoRemediationForm = () => {
		setAutoRemediationForm(true);
		onRemediationClose();
	};

	const hideAutoRemediationForm = () => setAutoRemediationForm(false);

	const submitRemediationForm = () => {
		try {
			setLoadingAutoRemediationSubmit(true);
			// This should be removed and replaced with valid logic
			setTimeout(() => {
				setLoadingAutoRemediationSubmit(false);
				hideAutoRemediationForm();
				dispatch(openSnackbar('success', 'Deny rule added'));
			}, 2000);
			// This should be removed and replaced with valid logic
		} catch {
			dispatch(openSnackbar('error', 'Something went wrong'));
		}
	};

	if (isLoading && !getFullScreenEventId()) {
		return <SkeletonTable columns={COLUMNS} rowsPerPage={MAX_ROW_IN_TABLE} className={classes.skeletonTable}/>;
	}

	const areFiltersApplied = () => {
		for (const key in filters) {
			if (Object.hasOwnProperty.call(filters, key) && filters[key].isDirty) {
				return true;
			}
		}

		return false;
	};

	const renderEmptyState = (
		<TableRow>
			<TableCell colSpan={COLUMNS.length + 1} rowSpan={MAX_ROW_IN_TABLE} align="center">
				<div className={classes.emptyStateContainer}>
					{ areFiltersApplied() ? <EmptyTableFilters/> : <EmptyTableNoFilters/> }
				</div>
			</TableCell>
		</TableRow>
	);

	const onGoToAnotherDetection = direction => {
		directionTypesActions[direction]();
	};

	const goToNextEvent = async () => {
		const newEventDataIndex = items.map(detection => detection.eventId).indexOf(eventData.eventId) + 1;
		const newEventId = items[newEventDataIndex].eventId;
		const lastPageIndex = (currentPage * pageSize) - 1;
		if (newEventDataIndex > lastPageIndex) {
			handlePageChange(null, currentPage + 1);
		}

		await openMasterDetails(newEventId);
		if (newEventDataIndex === totalItems - 1) {
			setCanGoNext(false);
		}
	};

	const goToPreviousEvent = async () => {
		const newEventDataIndex = items.map(detection => detection.eventId).indexOf(eventData.eventId) - 1;
		const newEventId = items[newEventDataIndex].eventId;
		const firstPageIndex = (currentPage * pageSize) - pageSize;
		if (newEventDataIndex < firstPageIndex) {
			handlePageChange(null, currentPage - 1);
		}

		await openMasterDetails(newEventId);
		if (newEventDataIndex === 0) {
			setCanGoPrevious(false);
		}
	};

	const directionTypesActions = {
		[directionTypes.NEXT]: goToNextEvent,
		[directionTypes.PREVIOUS]: goToPreviousEvent,
	};

	return (
		<div style={{ maxWidth: '100%', overflowX: 'auto' }}>
			<TableContainer className={classes.root} component={Paper}>
				<Table
					className={classes.table}
				>
					<TableHead
						order={sortDirection}
						orderBy={sortKey}
						onRequestSort={handleSort}
						columns={COLUMNS}
					/>
					<TableBody>

						{items.length === 0 ? (
							renderEmptyState
						) : (
							getCurrentPage().map(detection => (
								<TableRow
									onClick={() => openMasterDetails(detection.eventId)}
									key={detection.eventId}
									className={`${classes.tableRow} ${eventData && eventData.eventId === detection.eventId && classes.selectedDetection}`}
								>
									<TableCell className={classes.descriptionCell}>
										<div className={classes.descriptionContainer} >
											<div>
												<CustomTooltip title={getIconTooltipContent(detection.detectionType)}>
													<Icon className={classes.icon}>
														<img src={detectionIconsMapping[detection.detectionType]} alt={'detection icon'} className={classes.icon}/>
													</Icon>
												</CustomTooltip>
											</div>
											<TextWithTooltip text={detection.description} className={classes.description}/>
										</div>
									</TableCell>
									<TableCell className={classes.dateCell}>{toLocaleDateString(detection.firstDetectionTime)}</TableCell>
									<TableCell className={classes.severityCell}>
										<Severity
											severity={detection.severity}
											isCritical={detection.isCritical}
											config={SEVERITIES_CONFIG}/>
									</TableCell>
									<TableCell className={classes.endpointsCell}>
										{
											detection.mostSignificantEndpoints && detection.mostSignificantEndpoints.length > 0
												? <EndpointsGroup endpoints={detection.mostSignificantEndpoints}/>
												: '-'
										}
									</TableCell>
									<TableCell className={classes.iconsCell} align="right">
										<TableRowActions
											onDelete={event => handleDetectionUpdate(event, detection.eventId, detectionActionTypes.DELETE)}
											onHighlight={event => handleDetectionUpdate(event, detection.eventId, detection.isMarked ? detectionActionTypes.REMOVE_HIGHLIGHT : detectionActionTypes.HIGHLIGHT)}
											onRemediation={event => onRemediation(event, detection.eventId)}
											isHighlighted={detection.isMarked}
											hasRemediation={detection.remediation && detection.remediation.length > 0}
										/>
									</TableCell>
								</TableRow>
							))
						)}
						{_.times(pageSize - getCurrentPage().length, i => (
							<TableRow key={i}>
								<div className={classes.emptyRowsPlaceholder} />
							</TableRow>
						))}
					</TableBody>
					{
						items.length > 0 && <TableFooter>
							<TableRow>
								<TablePagination
									count={totalItems}
									rowsPerPage={pageSize}
									rowsPerPageOptions={[]}
									page={currentPage}
									labelDisplayedRows={() => ''}
									onPageChange={handlePageChange}
									ActionsComponent={PaginationActions}
									className={classes.pagination}
								/>
							</TableRow>
						</TableFooter>}
				</Table>
				<MasterDetails
					isOpen={isMasterDetailsOpen}
					eventData={eventData}
					isLoading={isMasterDetailsLoading}
					onClose={closeMasterDetails}
					onCloseFullScreen={closeFullScreenOnMD}
					onDelete={event => handleDetectionUpdate(event, eventData && eventData.eventId, detectionActionTypes.DELETE)}
					onHighlight={event => handleDetectionUpdate(event, eventData && eventData.eventId, eventData && eventData.isMarked ? detectionActionTypes.REMOVE_HIGHLIGHT : detectionActionTypes.HIGHLIGHT)}
					handlePageChange={handlePageChange}
					onGoToAnotherDetection={onGoToAnotherDetection}
					canGoNext={canGoNext}
					canGoPrevious={canGoPrevious}
					isFullScreen = {isFullScreen}
					history={history}
				/>
				{remediationEvent && <Popover
					id={remediationEvent.eventId}
					open={Boolean(remediationAnchorEl)}
					anchorEl={remediationAnchorEl}
					onClose={onRemediationClose}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
				>
					<RemediationPopover
						header="Next steps"
						data={remediationBuilder(remediationEvent.remediation)}
						onDismiss={onRemediationClose}
						onAutoRemediateClick={showAutoRemediationForm}
						enableAutoRemediate={enableAutoRemediate}
					/>
				</Popover>}
				{
					autoRemediationForm && <RemediationForm
						onDismiss={hideAutoRemediationForm}
						onSubmitForm={submitRemediationForm}
						isLoading={loadingAutoRemediationSubmit}
					/>
				}
			</TableContainer>
		</div>
	);
};

DetectionsTable.defaultProps = {
	filters: {},
	filtersConfig: {},
};

DetectionsTable.propTypes = {
	fetchDetections: PropTypes.func,
	pageSize: PropTypes.number,
	pagesToPreload: PropTypes.number,
	handleDelete: PropTypes.func,
	handleHighlight: PropTypes.func,
	handleRemediation: PropTypes.func,
	filters: PropTypes.object,
	filtersConfig: PropTypes.object,
	history: PropTypes.shape({
		push: PropTypes.func.isRequired,
	}),
};

export default DetectionsTable;
