import { Box, Grid } from '@material-ui/core';
import React from 'react';
import useStyles from './Overview.css';
import EndpointsGroup from '../../../../shared/components/endpointsGroup/endpointsGroup';
import PropTypes from 'prop-types';
import TextWithIcon from '../../../../shared/components/textWithIcon/textWithIcon';
import { toLocaleDateString } from '../../../../helpers/datesHelper';
import CalendarIcon from '../../../../assets/icons/calendar.svg';
import TextWithTooltip from '../../../../shared/components/textWithTooltip/textWithTooltip';
import { DETECTIONS_EXPLANATIONS } from '../../../../shared/components/detectionsTable/detectionsExplanations';
import {
	detectionIconsMapping,
	detectionNameMapping, SEVERITIES_CONFIG,
} from '../../../../shared/components/detectionsTable/detectionsTableConfig';
import Severity from '../../dataTable/Severity/severityDisplay';
import { remediationBuilder } from '../../../../shared/components/detectionsTable/helpers/parsers';
import { VCA_RT_FEATURE_TOGGLE } from "../../../../constants/featureToggles";


const LAST_UPDATED_FIELD = "Last Updated:";


const Overview = ({ eventData, isFullScreen }) => {
	const classes = useStyles({ isFullScreen });

	const getLastUpdatedFieldValue = () => {
		return toLocaleDateString(eventData.lastDetectionTime, true);
	}
	const getLastUpdatedClass = () => {
		return isFullScreen ? classes.lastUpdatedRowFullScreen : classes.lastUpdatedRow;
	}

	return (
		<Box className={classes.container}>
			<Grid item className={classes.infoChips}>
				<TextWithIcon text={detectionNameMapping[eventData.detectionType]} icon={detectionIconsMapping[eventData.detectionType]}/>
				<TextWithIcon text={toLocaleDateString(eventData.firstDetectionTime)} icon={CalendarIcon}/>
				<div className={classes.severityChip}>
					<Severity severity={eventData.severity} isCritical={eventData.isCritical} config={SEVERITIES_CONFIG}/>
				</div>
			</Grid>
			<p className={classes.headline}>{eventData.description}</p>
			<div>
				{
					VCA_RT_FEATURE_TOGGLE ?
					<div className={getLastUpdatedClass()}>
						<div className={classes.infoSubheader}>{LAST_UPDATED_FIELD} </div>
						<TextWithTooltip text={getLastUpdatedFieldValue()} className={classes.lastUpdated} maxLines={1}
										 placement="bottom-end"/>
					</div>
					: <></>
				}
				<div className={classes.descriptionRow}>
					<div className={classes.infoSubheader}>Description: </div>
					<TextWithTooltip text={eventData && DETECTIONS_EXPLANATIONS[eventData.detectionType](eventData).explanation} className={classes.description} maxLines={1} placement="bottom-end"/>
				</div>
				{
					eventData.mostSignificantEndpoints && eventData.mostSignificantEndpoints.length > 0
							&& <div className={classes.endpointsRow}>
								<div className={classes.infoSubheader}>Endpoints: </div>
								<EndpointsGroup endpoints={eventData.mostSignificantEndpoints} showRemainderPopover />
							</div>
				}
				{
					eventData.remediation && eventData.remediation.length > 0
					&& <div className={classes.remediationRow}>
						<div className={classes.infoSubheader}>Remediation: </div>
						<ol type="1">
							{
								remediationBuilder(eventData.remediation).map((step, index) => <li key={`${step}-${index}`}>{step}</li>)
							}
						</ol>
					</div>
				}
			</div>
		</Box>
	);
};

Overview.defaultProps = {
	isFullScreen: false,
};

Overview.propTypes = {
	eventData: PropTypes.object.isRequired,
	isFullScreen: PropTypes.bool,
};

export default Overview;
