import http from '../../../services/httpService/http';
import { vcaUrlBuilder } from './utils';
import { HIGHLIGHTED_RECORDS_URL, DETECTION_CENTER_URL, DETECTION_CENTER_COUNT_URL } from './urls';


const OVERVIEW_VCA_FIELDS = [
	'accountId',
	'additionalInfo',
	'description',
	'detectionType',
	'eventId',
	'firstDetectionTime',
	'lastDetectionTime',
	'severity',
	'isCritical',
].join(',');


const getHighlightedRecords = () => http.get(HIGHLIGHTED_RECORDS_URL);

const getAllRecords = (from, to, limit, detectionTypes, sortKey, sortDirection) => http.get(
	DETECTION_CENTER_URL,
	{ params: { from, to, pageSize: limit, type: detectionTypes.join(','), sortKey, sortDirection, projection: OVERVIEW_VCA_FIELDS } }
);

const getLightweightData = (detectionTypes, from, to, pageSize, pageIndex, severities, sortKey, sortDirection, filters) => http.get(DETECTION_CENTER_URL, { params: { from, to, pageSize, pageIndex,
	type: detectionTypes.join(','), severity: severities.join(','), sortKey, sortDirection, totalItems: true, ...filters } });

const updateDetection = (eventId, updateDetails) => http.put(`${DETECTION_CENTER_URL}/${eventId}`, updateDetails);

export const getDetectionDetails = eventId => {
	const url = `${DETECTION_CENTER_URL}/${eventId}`;
	return http.get(url);
};

export const exportRawdata = eventId => {
	const url = `${DETECTION_CENTER_URL}/${eventId}/rawdata`;
	return http.get(url);
};

export const getDetectionsCount = (from, to) => {
	const params = {
		from,
		to,
	};
	return http.get(DETECTION_CENTER_COUNT_URL, { params });
};

export {
	getHighlightedRecords,
	getAllRecords,
	getLightweightData,
	updateDetection,
};
