import lowAlert from '../../../assets/threats/grey-alert.svg';
import mediumAlert from '../../../assets/threats/yellow-alert.svg';
import highAlert from '../../../assets/threats/red-alert.svg';
import criticalAlert from '../../../assets/threats/dark-red-alert.svg';
import VcaIcon from '../../../assets/icons/vca.svg';
import FindingsTable from '../../../containers/vcaManagement/findingsTable/findingsTable';
import Rawdata from '../../../containers/vcaManagement/rawdata/rawdata';
import FindingsGraph from '../../../containers/groupManagement/view/findingsGraph/findingsGraph';
import AlertIcon from '../../../assets/icons/AlertIcon.svg';

export const COLUMNS = [
	{ sortParam: 'description', label: 'Detection', sort: true },
	{ sortParam: 'firstDetectionTime', label: 'Date', sort: true },
	{ sortParam: 'severity', label: 'Severity', sort: true },
	{ sortParam: '', label: 'Endpoints', sort: false },
];

export const SEVERITIES_CONFIG = {
	default: { icon: '', label: '', min: -1, max: -1 },
	low: { icon: lowAlert, label: 'low', min: 0, max: 4 },
	medium: { icon: mediumAlert, label: 'medium', min: 4.1, max: 8.4 },
	high: { icon: highAlert, label: 'high', min: 8.5, max: 10 },
	critical: { icon: criticalAlert, label: 'critical', min: -1, max: -1 },
};

export const DETECTION_TYPES = {
	VCA: 'vca',
	VCA_RT: 'vca_realtime',
	CUSTOM_ALERT: 'custom_alert',
};

export const detectionNameMapping = {
	[DETECTION_TYPES.VCA]: 'Virtual Cyber Analyst',
	[DETECTION_TYPES.VCA_RT]: 'Virtual Cyber Analyst',
	[DETECTION_TYPES.CUSTOM_ALERT]: 'Alert',
};

export const detectionActionTypes = {
	DELETE: 'delete',
	UNDO_DELETE: 'undoDelete',
	HIGHLIGHT: 'highlight',
	REMOVE_HIGHLIGHT: 'removeHighlight',
};

export const actionTypeToPayload = {
	[detectionActionTypes.DELETE]: {
		isHidden: true,
	},
	[detectionActionTypes.UNDO_DELETE]: {
		isHidden: false,
	},
	[detectionActionTypes.HIGHLIGHT]: {
		isMarked: true,
	},
	[detectionActionTypes.REMOVE_HIGHLIGHT]: {
		isMarked: false,
	},
};

export const detectionIconsMapping = {
	[DETECTION_TYPES.VCA]: VcaIcon,
	[DETECTION_TYPES.VCA_RT]: VcaIcon,
	[DETECTION_TYPES.CUSTOM_ALERT]: AlertIcon,
};

export const directionTypes = {
	NEXT: 'next',
	PREVIOUS: 'previous',
};

export const findingsComponentMapping = {
	[DETECTION_TYPES.VCA]: FindingsTable,
	[DETECTION_TYPES.VCA_RT]: FindingsTable,
	[DETECTION_TYPES.CUSTOM_ALERT]: FindingsGraph,
};

export const assetTypes = {
	PORT: 'PORT',
	IP: 'IP',
};

export const rawdataComponentMapping = {
	[DETECTION_TYPES.VCA]: Rawdata,
	[DETECTION_TYPES.VCA_RT]: Rawdata,
	[DETECTION_TYPES.CUSTOM_ALERT]: Rawdata,
};
