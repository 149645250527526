import { calculateSeverity, generateTimeStamps } from '../../../../vcaManagement/services/utils';
import { getAllRecords } from '../../../../vcaManagement/services/vcaRequests';
import { filterUnknownLogics } from '../../../../vcaManagement/services/tableDataPrep';
import { DETECTION_TYPES, VCA_SEVERITIES_CONFIG } from '../../../../vcaManagement/vcaManagementConfig';
import vcaLogicConfig from '../../../../vcaManagement/services/vcaLogicConfig.json';
import {VCA_RT_FEATURE_TOGGLE} from "../../../../../constants/featureToggles";


const VCA_NOTIFICATION_TYPE = 'vca';
const SORT_KEY = 'lastDetectionTime';
const SORT_DIRECTION = 'desc';
const QUERY_RES_ITEMS_LIMIT = 10;
const VCA_DETECTION_TYPES = VCA_RT_FEATURE_TOGGLE ? [DETECTION_TYPES.VCA, DETECTION_TYPES.VCA_RT] : [DETECTION_TYPES.VCA]


const fetchVcaDetections = async () => {
	const [from, to] = generateTimeStamps(2);
	let vcaDetections = await getAllRecords(from, to, QUERY_RES_ITEMS_LIMIT, VCA_DETECTION_TYPES, SORT_KEY, SORT_DIRECTION);
	vcaDetections = filterUnknownLogics(vcaDetections.data);
	return vcaDetections.map(detection => buildAlertObject(detection));
};

const buildAlertObject = detection => {
	const logicDataObject = vcaLogicConfig.detectionLogicsDict[detection.additionalInfo.logicKey][detection.additionalInfo.logicVersion];
	const message = `${logicDataObject.dashboard.descriptionPrefix} ${
		detection.additionalInfo.topConnections
			? `${logicDataObject.dashboard.topConnectionsPrefix} ${generateIPsString(detection.additionalInfo.topConnections)}`
			: ''
	}`;

	return {
		type: VCA_NOTIFICATION_TYPE,
		id: detection.eventId,
		time: detection.lastDetectionTime,
		severity: calculateSeverity(VCA_SEVERITIES_CONFIG, detection.isCritical, detection.severity),
		title: detection.description,
		message,
		queryId: detection.additionalInfo.linkToQuery.queryId,
		isCritical: detection.isCritical
	};
};

const generateIPsString = connections => {
	const formattedConnections = connections.map(record => [record.srcIp, record.dstIp].join(' - '));

	return formattedConnections.join(', ');
};

export { VCA_NOTIFICATION_TYPE, fetchVcaDetections };
