import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
	palette: {
		primary: {
			main: '#00b8ec',
			hover: '#377EA1',
			disabled: '#F5F5F5FF',
			white: '#FFFFFF',
		},
		secondary: {
			main: '#071C36',
		},
		inputText: {
			main: '#425262',
		},
		text: {
			secondary: '#48648B',
			light: '#FFFFFF',
		},
		error: {
			main: '#f05f5a',
			hover: '#AD322E',
		},
		border: {
			main: '#a8c1e4',
		},
		selectorMenuHover: {
			main: '#EFF9FB',
		},
		advancedButtons: {
			main: '#7287a5',
		},
		advancedButtonsHover: {
			main: '#425262',
		},
		dialogDescription: {
			main: '#48648b',
			disabled: '#8c8c8c',
		},
		walkthroughPanel: {
			main: '#f2f5f6',
		},
		severity: {
			critical: '#B82A33',
			high: '#F05F5A',
			medium: '#ECAA00',
			low: '#7287A5',
		},
		stepsText: {
			notActive: '#9CA0A4',
			active: '#00b8ec',
		},
		secondaryTitle: {
			main: '#979797',
		},
		selectCreatableTags: {
			include: '#7BAB7A',
			exclude: '#E5918E',
		},
		table: {
			border: '#E0E0E0',
		},
		graph: {
			aboveThreshold: '#f05f5a',
			belowThreshold: '#24BB63',
			point: '#48648B',
			outerCircle: 'rgba(72, 100, 139, 0.30)',
		},
		chips: {
			success: '#006C2D',
			failure: '#BA0C0C',
			successBackground: '#E9F8EF',
			failureBackground: 'rgba(240, 95, 90, 0.1)',
		},
	},
	typography: {
		fontFamily: '\'Source Sans Pro\', sans-serif',
		subtitle: {
			fontSize: '20px !important',
			fontWeight: 'bold !important',
		},
		subtext: {
			fontSize: '18px !important',
		},
	},
	components: {
		MuiTypography: {
			defaultProps: {
				variantMapping: {
					poster: 'h1',
				},
			},
		},
	},
	overrides: {
		MuiTextField: {
			root: {
				WebkitBoxShadow: '0 0 0 1000px white inset',
				'&:-webkit-autofill': {
					WebkitBoxShadow: '0 0 0 1000px white inset',
				},
				fontSize: '14px',
			},
			input: {
				WebkitBoxShadow: '0 0 0 1000px white inset',
				'&:-webkit-autofill': {
					WebkitBoxShadow: '0 0 0 1000px white inset',
				},

			},
		},
		MuiInputLabel: {
			root: {
				fontSize: '14px',
				color: '#425262',
			},
		},
		MuiOutlinedInput: {
			root: {
				'&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
					border: 'solid 1px #00b8ec',
				},
			},
			notchedOutline: {
				borderColor: '#a8c1e4',
				borderRadius: '4px',
			},
		},
		MuiFormHelperText: {
			root: {
				fontWeight: 'bold',
				fontSize: '12px',
				marginBottom: '0px !important',
			},
		},
		MuiButton: {
			root: {
				textTransform: 'none',
				fontSize: '16px !important',
			},
			contained: {
				color: '#EFF9FB !important',
			},
		},
		MuiAccordionDetails: {
			root: {
				display: 'block',
			},
		},
		MuiAccordionSummary: {
			root: {
				'&$expanded': {
					minHeight: '55px',
				},
			},
			content: {
				'&$expanded': {
					margin: '0',
				},
			},
		},
		MuiTooltip: {
			tooltip: {
				fontSize: '14px',
				backgroundColor: '#4A4A4A',
			},
		},
		MuiTableCell: {
			root: {
				padding: '10px',
				fontSize: '14px',
			},
		},
		MuiTab: {
			root: {
				padding: '4px 8px',
			},
		},
		MuiInputBase: {
			root: {
				fontSize: '14px',
			},
		},
	},
	props: {
		MuiButton: {
			disableElevation: true,
		},
	},
	breakpoints: {
		values: {
			xs: 300,
			sm: 900,
			md: 970,
			lg: 1300,
			xl: 1536,
		},
	},
});

export { theme };
