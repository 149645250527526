const filterAddressesByType = (addresses, type) => {
    return addresses.reduce((filtered, addressObj) => {
        if (addressObj.type === type) {
            filtered.push(addressObj.address);
        }
        return filtered;
    }, [])
}

export {
    filterAddressesByType
}