import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import CloseIcon from 'mdi-react/CloseIcon';
import './noNotification/notification.scss';
import statusWarningIcon from '../../../../assets/icons/yellow-alert.svg';
import statusDangerIcon from '../../../../assets/icons/red-alert.svg';
import statusCriticalIcon from '../../../../assets/icons/dark-red-alert.svg';
import statusLowDangerIcon from '../../../../assets/threats/grey-alert.svg';
import _ from 'lodash';
import NotificationFeedbacks from './feedbackLoop/feedbackLoop';
import RoundCloseButton from '../../../../shared/components/form/Buttons/roundCloseButton';
import AnalysisIcon from '../../../../shared/components/AnalysisIcon/AnalysisIcon';
import { TEXTS } from '../../configSummaryView';
import { createQuery } from '../../../../services/linkToQueryService';
import { setPlansFlowActivation } from '../../../plans/redux/plansActions';
import { FEATURES } from '../../../../constants/planFields';
import { ReactComponent as VisibilityUpsellIcon } from '../../../../assets/upsellIcons/visibility-upsell.svg';

const SEVERITY = {
	critical: statusCriticalIcon,
	high: statusDangerIcon,
	medium: statusWarningIcon,
	low: statusLowDangerIcon,
};

class Notification extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	feedback = newValue => {
		console.log('feedback', newValue);
	};

	linkToQuery = queryId => {
		createQuery(queryId, TEXTS);
	};

	renderTitle = () => {
		const { notification: { title, identifierValue, ip } } = this.props;

		let notificationTitle = title;
		if (ip) {
			notificationTitle += ` - ${ip}`;
		} else if (identifierValue) {
			notificationTitle += ` - ${identifierValue}`;
		}

		return notificationTitle;
	};

	handleOpenPlansFlow = () => {
		this.props.setPlansFlowActivation(true, true, FEATURES.CUSTOM_ALERTS);
	};

	render() {
		const { isNew, showNewNotification, notification, removeNotification } = this.props;

		if (!notification) {
			return null;
		}

		const severityIcon = SEVERITY[notification.severity];
		let classes = ['threat-wrapper'];
		classes = isNew ? [...classes, 'new-alertNotifications'] : classes;
		classes = showNewNotification ? [...classes, 'show-alertNotifications'] : classes;
		const time = moment(notification.time).format('HH:mm');
		return (
			<div
				className={classes.join(' ')}
				style={{
					backgroundColor: isNew
						? (notification.severity === 'high' ? '#facfce' : '#ffe9b3')
						: '',
				}}>
				<div className="threat">
					<div className="threat-severity-time">
						<img src={severityIcon} className="threat-severity" alt=""/>
						<div className="threat-time" style={isNew ? { fontWeight: 'bold' } : {}}>{time}</div>
					</div>
					<div className="threat-message">
						<div className="threat-title" style={isNew ? { fontWeight: 'bold' } : {}}>
							{this.renderTitle()}
						</div>
						<div className="threat-description">
							{notification.message}
						</div>
					</div>
				</div>
				<div className={'actions'}>
					<RoundCloseButton
						onClick={removeNotification}/>
					<div className="analysis-icon">
						{notification.queryId
							? <AnalysisIcon onClick={() => this.linkToQuery(notification.queryId)}/>
							: <VisibilityUpsellIcon
								style={{ cursor: 'pointer' }}
								onClick={ this.handleOpenPlansFlow }
							/>
						}
					</div>
				</div>

			</div>
		);
	}
}

const mapDispatchToProps = {
	setPlansFlowActivation,
};
export default connect(null, mapDispatchToProps)(Notification);
